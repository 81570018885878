<template>
    <div>
      <group-form
        :is-group-form-sidebar-active.sync="isGroupFormSidebarActive"
            :group-data="groupData"
            @refetch-data="refetchData" 
        />
        <navigation-form
        :is-navigation-form-sidebar-active.sync="isNavigationFormSidebarActive"
            :navigation-data="navigationData"
            @refetch-data="refetchData" 
        />
        <!--<b-card no-body
        >
          <div class="m-2">
            <div class="d-flex align-items-center justify-content-end">
                <b-button
                variant="primary"
                @click="addGroup"
              >
                <span class="text-nowrap">Add Group</span>
              </b-button>
            </div>
          </div>
        </b-card>-->

        <b-card v-for="(ng,key) in groups" :key="'group'+key" action-collapse  >
          <b-card-header class="pb-50">
            
            <b-row class="w-100">
              <b-col  cols="10">
                <h3>
                  {{ ng.title }}
                </h3>
              </b-col>
              <b-col cols="2">
                <div class="d-flex align-items-center justify-content-end">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    @click="addNav(ng.id)"
                    class="btn-icon"
                  >
                  <feather-icon icon="PlusCircleIcon" />
                  </b-button>
                </div>
              </b-col>
            </b-row>
            
          </b-card-header>
          <b-card-body>
            
            <draggable
              :list="ng.navigations"
              tag="ul"
              @change="undateOrdering(key)"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(itm, index) in ng.navigations"
                :key="index"
                tag="li"
              >
              
                  <b-row >
                    <b-col cols="10">
                      <div class="p-1">
                        {{ itm.title }}
                      </div>
                    </b-col>
                    <b-col cols="2">
                      <div class="d-flex  justify-content-end">
                        <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="btn-icon"
                      @click="updateNav(itm)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="btn-icon ml-1"
                      @click="deleteconfirm(itm.id)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                      </div>
                    </b-col>
                  </b-row>
                  
                 

                
              
              </b-list-group-item>
            </draggable>
            <!--<vue-drag-tree :data='data' :allowDrag='allowDrag' :allowDrop='allowDrop' :defaultText='"New Node"' @current-node-clicked='curNodeClicked' @drag="dragHandler" @drag-enter="dragEnterHandler" @drag-leave="dragLeaveHandler" @drag-over="dragOverHandler" @drag-end="dragEndHandler" @drop="dropHandler" v-slot="slotProps">
               
                <span :class="[slotProps.isClicked ? 'i-am-clicked' : 'i-am-not-clicked']"></span>
                <span class='i-am-node-name'>{{slotProps.nodeName}}</span>
            </vue-drag-tree>-->
          </b-card-body>
        </b-card>
       
    </div>
</template>
<script>


import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
  BListGroupItem,BCardHeader,BCardBody
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
//api
import axios from '@axios'
import store from '@/store'
import { ref,onUnmounted } from '@vue/composition-api'
import useNavigationList from './useNavigationList'
import navigationStoreModule from '../navigationStoreModule'
import GroupForm from '../navigation-form/GroupForm.vue'
import NavigationForm from '../navigation-form/NavigationForm.vue'


import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
export default{
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        BCardActions,
        Prism,
        draggable,
        BListGroupItem,
        GroupForm,
        BCardHeader,
        BCardBody,
        NavigationForm
        
        

    },
    directives:{
        'b-modal': VBModal,
        Ripple

    },
    setup(){

      const NAVIGATION_STORE_MODULE_NAME = 'app-navigation';
        if (!store.hasModule(NAVIGATION_STORE_MODULE_NAME)) store.registerModule(NAVIGATION_STORE_MODULE_NAME, navigationStoreModule)
        onUnmounted(() => {
            if (store.hasModule(NAVIGATION_STORE_MODULE_NAME)) store.unregisterModule(NAVIGATION_STORE_MODULE_NAME)
        });
        const {
          groups,
          undateOrdering,
          updateGroup,
          refetchData,
          isGroupFormSidebarActive,
          isNavigationFormSidebarActive,
          groupData,
          addGroup,
          addNav,
          updateNav,
          navigationData,
          deleteData,
          deleteGroupData

        } = useNavigationList();
        return {
          groups,
          undateOrdering,
          updateGroup,
          refetchData,
          isGroupFormSidebarActive,
          isNavigationFormSidebarActive,
          groupData,
          addGroup,
          addNav,
          updateNav,
          navigationData,
          deleteData,
          deleteGroupData

        }
    },
    data(){
        return {
            data: [
                {
                name: 'Node 0-0',
                id: 0,
                children: [
                    {
                    name: 'Node 1-1',
                    id: 3,
                    children: [
                        {
                        name: 'Node 2-1',
                        id: 4,
                        children: []
                        },
                        {
                        name: 'Node 2-2',
                        id: 10,
                        children: []
                        }
                    ]
                    },
                    {
                    name: 'Node 1-2',
                    id: 13,
                    children: []
                    }
                ]
                },
                {
                name: 'Node 0-1',
                id: 14,
                children: []
                }
            ],
            navigations_groups:[
                {
                    id:1,
                    title:'Header',
                    slug:'header'
                },
                {
                    id:2,
                    title:'Footer',
                    slug:'footer'
                }
            ],
            navigations: [
                { text: "node 1" },
                { text: "node 2" },
                { text: "node 3 undraggable" },
                { text: "node 4" },
                { text: "node 4 undroppable", droppable: false },
            ]
        }
    },
    methods:{
      deleteconfirm(id){
                this.$bvModal
                    .msgBoxConfirm('Please confirm that you want to delete Navigation ID.'+id, {
                    title: 'Please Confirm',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                    })
                    .then(value => {
                    if(value === true){
                    this.deleteData(id)
                    }
                
                    //this.deleteData(id)
                    })
    
    },
    deleteGroupconfirm(id){
                this.$bvModal
                    .msgBoxConfirm('Please confirm that you want to delete Group ID.'+id, {
                    title: 'Please Confirm',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                    })
                    .then(value => {
                    if(value === true){
                    this.deleteGroupData(id)
                    }
                
                    //this.deleteData(id)
                    })
    
    },
        allowDrag(model, component) {
      if (model.name === 'Node 0-1') {
        // can't be dragged
        //return false;
      }
      // can be dragged
      return true;
    },
    allowDrop(model, component) {
      if (model.name === 'Node 2-2') {
        // can't be placed
        //return false;
      }
      // can be placed
      return true;
    },
    curNodeClicked(model, component) {
      // console.log('curNodeClicked', model, component);
    },
    dragHandler(model, component, e) {
      // console.log('dragHandler: ', model, component, e);
    },
    dragEnterHandler(model, component, e) {
      // console.log('dragEnterHandler: ', model, component, e);
    },
    dragLeaveHandler(model, component, e) {
      // console.log('dragLeaveHandler: ', model, component, e);
    },
    dragOverHandler(model, component, e) {
      // console.log('dragOverHandler: ', model, component, e);
    },
    dragEndHandler(model, component, e) {
      // console.log('dragEndHandler: ', model, component, e);
    },
    dropHandler(model, component, e) {
      // console.log('dropHandler: ', model, component, e);
    }
    }
}

</script>